body {
  background-color: #e6ffe6;
  padding: 0px;
  margin: 0;
}


.leaflet-container {
  width: 100%;
  height: 100vh;
}


.full-screen-map{
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  order: 10;
}

.overlay-panel{
  position: fixed;
  overflow: hidden;
  height: 300px;
  width: 550px;
  left: 10px;
  top: 50px;
  background-color: rgba(255,255,255,0.6);
  z-index: 500;
  border-radius: 20px 20px 20px 20px ;
}

.overlay-panel-info{
  position: fixed;
  overflow: hidden;
  height: 100px;
  width: 25%;
  left: 10px;
  bottom: 20px;
  background-color: rgba(255,255,255,0.6);
  z-index: 500;
  border-radius: 20px 20px 20px 20px ;
}

.any-text{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.fade-in{
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  z-index: 500;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-axis-tick {
  font-size: 15px;
  font-family: Roboto, sans-serif;
}

.recharts-text {
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

.recharts-legend-item-text {
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

.recharts-tooltip-item-name {
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.recharts-tooltip-item-value {
  font-size: 14px;
  font-family: Roboto, sans-serif;
}
.recharts-tooltip-label {
  font-size: 16px;
  font-family: Roboto, sans-serif;
}